/**
 * @prettier
 */

import 'react_ujs'
import { CampaignsTip } from './widget/app/shared/campaigns_tip'
import { DeviceSwitcher } from './widget/app/shared/device_switcher'
import { LanguageSelector } from './widget/app/shared/language_selector'
import { BaseTextLabel } from './widget/app/components/base_text_label'
import { BaseTextLabelStatus } from './widget/app/components/base_text_label_status'
import { InfoSidebarAdvancedCriteria } from './widget/app/components/info_sidebar_advanced_criteria'
import { ViewSetupPreview } from './widget/app/components/view_setup_preview'
import { ViewSetupPreviewEvent } from './widget/app/components/view_setup_preview_event'
import { LocalizationItemABStatus } from './widget/app/locale_entries/components/localization_item_ab_status'

Widget.InfoSidebarAdvancedCriteria = InfoSidebarAdvancedCriteria
Widget.CampaignsTip = CampaignsTip
Widget.DeviceSwitcher = DeviceSwitcher
Widget.LanguageSelector = LanguageSelector
Widget.BaseTextLabel = BaseTextLabel
Widget.BaseTextLabelStatus = BaseTextLabelStatus
Widget.ViewSetupPreviewEvent = ViewSetupPreviewEvent
Widget.LocalizationItemABStatus = LocalizationItemABStatus

window.ViewSetupPreview = {
  UI: ViewSetupPreview,
}
