/**
 * @prettier
 */
import { InfoSidebarProvider } from 'components/shared/info_sidebar/info_sidebar_provider'

export class InfoSidebarAdvancedCriteria extends React.PureComponent {
  static propTypes = {
    data: PT.object.isRequired,
    buttonText: PT.string.isRequired,
  }

  render() {
    return (
      <InfoSidebarProvider
        title={() => 'Variables'}
        data={this.props.data}
        render={toggleInfoSidebar => (
          <div className="link js-keep-propagating" onClick={toggleInfoSidebar}>
            {this.props.buttonText}
          </div>
        )}
      />
    )
  }
}
