window.talkableLocalStorage = (() => {
  class TalkableLocalStorage {
    constructor() {
      if (!window.localStorage) {
        handleError(new Error('No local storage available'))
      } else if (window.talkableLocalStorage) {
        // eslint-disable-next-line no-constructor-return
        return window.talkableLocalStorage
      } else {
        this._storage = window.localStorage
        this._syncLength()
      }
    }

    _syncLength() {
      this.length = this._storage.length
    }

    _executeStorageMethod(methodName) {
      return (...args) => {
        const result = _.attempt(this._storage[methodName].bind(this._storage), ...args)

        return _.isError(result)
          ? handleError(result)
          : result
      }
    }

    setItem(key, value) {
      if (_.isUndefined(value)) {
        handleError(new TypeError("Failed to execute 'setItem' on 'Storage': 2 arguments required, but only 1 present."))
      }
      this._executeStorageMethod('setItem')(key, JSON.stringify(value))
      this._syncLength()
    }

    removeItem(key) {
      this._executeStorageMethod('removeItem')(key)
      this._syncLength()
    }

    getItem(key) {
      return JSON.parse(this._executeStorageMethod('getItem')(key))
    }

    clear() {
      this._executeStorageMethod('clear')()
      this._syncLength()
    }

    key(key) {
      return this._executeStorageMethod('key')(key)
    }
  }

  return new TalkableLocalStorage()


  /* -----------------------------
    Helpers
  ----------------------------- */

  function handleError(error) {
    if (window.Bugsnag) {
      Bugsnag.notify(error)
    } else {
      console.error(params)
    }
  }
})()
