/**
 * @prettier
 */

import { types, flow, getRoot } from 'mobx-state-tree'

import { STATIC_WIDGET_GA_CATEGORY } from 'utils/constants'

import { StaticWidgetInfoStore } from './static_widget_info_store'

export const StaticWidgetStore = types
  .model('StaticWidgetStore', {
    campaign_id: types.maybeNull(types.number),
    dataFetched: false,
    disabled_state: types.maybeNull(types.boolean),
    id: types.identifier,
    showStaticWidgetModal: false,
    site_cached_slug: '',
    staticWidgetInfo: types.optional(StaticWidgetInfoStore, {}),
    tooltipDescription: '',
  })
  .views(self => ({
    get rootStore() {
      return getRoot(self)
    },
    get siteCachedSlug() {
      return self.site_cached_slug || self.siteCachedSlugFromRoot
    },
    get site() {
      return self.rootStore.models.site
    },
    get siteCachedSlugFromRoot() {
      return self.site.siteCachedSlug
    },
    get isLoading() {
      return self.disabled_state ? false : !self.dataFetched
    },
    get tooltipCanBeShown() {
      return !self.isLoading
    },
    get tooltipText() {
      return self.tooltipDescription || self.staticWidgetInfo.tooltipText
    },
  }))
  .actions(self => ({
    changeDataFetched: state => {
      self.dataFetched = state
    },
    fetchData: flow(function* () {
      if (self.disabled_state) return

      self.changeDataFetched(false)
      try {
        const data = yield $.ajax({
          url: Routes.static_widget_info_internal_site_campaign_path(
            self.siteCachedSlug,
            self.campaign_id,
          ),
          data: {
            no_spinner: true,
          },
        })

        self.updateData(data.result)
      } catch (xhr) {
        notifyDevelopers({
          name: 'Unable to fetch static widget info data',
          message: 'Ajax failure',
          xhr,
        })
      }
      Utils.trackGAEvent(STATIC_WIDGET_GA_CATEGORY, 'fetch data')
    }),
    updateData: data => {
      self.staticWidgetInfo = StaticWidgetInfoStore.create(data)
      self.changeDataFetched(true)
    },
    showModal: () => {
      self.showStaticWidgetModal = true
      Utils.trackGAEvent(STATIC_WIDGET_GA_CATEGORY, 'show modal')
    },
    hideModal: () => {
      self.showStaticWidgetModal = false
      Utils.trackGAEvent(STATIC_WIDGET_GA_CATEGORY, 'hide modal')
    },
  }))
