/**
 * @prettier
 */
window.showSpinner = function (text) {
  const loading = $('#loadingDiv')
  const loadingMessage = loading.find('.loadingMessage')

  if (text) {
    loadingMessage.html(text)
  }

  loading.addClass('is-fixed').fadeIn(300)
}

window.hideSpinner = function () {
  const loading = $('#loadingDiv')

  loading.fadeOut(300)
}

document.addEventListener('DOMContentLoaded', () => {
  $('.js-load-spinner-on-submit').on('click.loading', function (event) {
    if (!(event.ctrlKey || event.metaKey || event.which === 2)) {
      showSpinner()
    }
  })

  $(document).keydown(function (event) {
    if (event.which === 27) {
      // hide spinner when press esc
      hideSpinner()
    }
  })
})
