/**
 * @prettier
 */
$(function () {
  let urls_map = {}

  $('.js-async-count').each(function () {
    let $this = $(this)
    let url = $this.data('url')

    urls_map[url] = urls_map[url] || []
    urls_map[url].push($this)
  })

  $.each(urls_map, function (url, elements) {
    $.ajax({
      url,
      dataType: 'json',
      data: {
        no_spinner: true,
      },
      success(data) {
        $.each(elements, function (index, element) {
          let parent = element.parent()

          element.replaceWith(data.result[element.data('view')])
          parent.trigger('async_count_replaced')
        })
      },
    })
  })
})
