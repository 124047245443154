/**
 * @prettier
 */

import { types } from 'mobx-state-tree'

const PreviewData = types
  .model('previewData', {
    desktop: '',
    mobile: '',
    tablet: '',
  })
  .views(self => ({}))
  .actions(self => ({}))

export const StaticWidgetInfoStore = types
  .model('StaticWidgetInfoStore', {
    published: types.maybeNull(types.boolean),
    eligible: types.maybeNull(types.boolean),
    fresh: types.maybeNull(types.boolean),
    ineligible_reasons: types.optional(types.array(types.string), []),
    preview: types.optional(PreviewData, {}),
  })
  .views(self => ({
    get isActive() {
      return self.published && self.fresh
    },
    get tooltipText() {
      let result = ''

      if (_.some(self.ineligible_reasons) || !self.eligible) {
        result = 'Could not publish campaign changes. <br /> Click here for more details'
      } else if (!self.fresh) {
        result = 'Publishing campaign changes…'
      } else {
        result = 'Static trigger widget is working'
      }

      return result
    },
    get errorText() {
      return self.ineligible_reasons.join('\n')
    },
    get ineligibleReasonsExists() {
      return _.some(self.ineligible_reasons)
    },
  }))
  .actions(self => ({}))
