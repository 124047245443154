window.ReferralApprovalActions = {
  init() {
    $(".js-referral-action").on("click", event => {
      const $buttonsHolder = $(this).parent()

      $buttonsHolder.addClass("is-loading")
      $buttonsHolder.children().hide()

      return $buttonsHolder.append("<div class='preloader'></div>")
    }).on("ajax:success", () => {
      return window.location.reload()
    }).on("ajax:error", () => {
      return alert("Status cannot be transitioned")
    })
  }
}
