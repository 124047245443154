/* eslint-disable */
/*global $:false */
$(function () {
  "use strict";

  var chosenOptions = {
    width: '100%',
    search_contains: true,
    allow_single_deselect: true,
    inherit_select_classes: true
  };

  $('.js-ajax-select').ajaxChosen({
    type: 'GET',
    dataType: 'json'
  }, null, chosenOptions);

  $('.js-select').chosen($.extend({}, chosenOptions, {
    disable_search_threshold: 5
  }));

  $('.js-select-without-search').chosen({
    width: '100%',
    disable_search: true
  });

  $('.js-campaign-new-select').on('click', function(e) {
    if ($(this).hasClass('collapsed')) {
      var id = $(this).attr("href");

      setTimeout(function() {
        $('html, body').animate({
          scrollTop: $(id).offset().top
        }, 500);
      }, 100);
    }
  });

  $('.js-checkbox-tree').tristate();

  if ($('.js-campaign-qa-preview').length) {
    UI.previewSubscribe('responsive_iframe_size', function(data) {
      var iframe_selector = 'iframe[name='+data.iframe_name+']';

      var containerWidth  = $(iframe_selector).parent().width(),
          containerHeight = $(iframe_selector).parent().height();

      $(iframe_selector).height(iframeHeight);

      var iframeSize   = data.data,
          iframeWidth  = iframeSize.width,
          iframeHeight = iframeSize.height,
          ratio = iframeWidth / iframeHeight,
          result = previewMinimize(ratio, containerWidth, containerHeight),
          wpercent = _.round(result.tempWidth / iframeWidth, 2),
          hpercent = _.round(result.tempHeight / iframeHeight, 2);

      previewScale(iframe_selector, wpercent, hpercent);
      previewPosition(iframe_selector);
    });
  }

  function previewScale(selector, wp, hp) {
    if (wp > 1 || hp > 1) {
      wp = 1
      hp = 1
    }
    $(selector).css({
      '-webkit-transform' : 'scale(' + wp + ',' + hp + ')',
      '-moz-transform'    : 'scale(' + wp + ',' + hp + ')',
      '-ms-transform'     : 'scale(' + wp + ',' + hp + ')',
      'transform'         : 'scale(' + wp + ',' + hp + ')'
    });
  }

  function previewPosition(selector) {
    $(selector).css({
      'top'         : 'auto',
      'left'        : 'auto'
    });
  }

  var previewMinimize = function(ratio, width, height) {
    var tempWidth,
        tempHeight;

    if (ratio > 1) {
      tempWidth   = width;
      tempHeight  = Math.round(tempWidth / ratio);
    } else {
      tempHeight  = height;
      tempWidth   = Math.round(tempHeight * ratio);
    }

    return { tempWidth: tempWidth,  tempHeight: tempHeight };
  };

  var $campaignPreviews = $(".js-campaign-previews");

  if ($campaignPreviews.length) {
    $(window).on("scroll.preview resize.preview lookup.preview", function() {
      var previewTop    = $campaignPreviews.offset().top,
          previewHeight = $campaignPreviews.height(),
          windowTop     = $(this).scrollTop(),
          windowHeight  = $(this).height();

      if (previewTop + previewHeight >= windowTop && previewTop <= windowTop + windowHeight) {
        $(this).off("scroll.preview resize.preview lookup.preview");
        loadPreviews();
        Utils.trackGAEvent('Campaign Details', 'Load Visible Previews', Site.cached_slug);
      }
    });
  } else {
    loadPreviews();
  }

  function loadPreviews() {
    var queue = 1;
    var $preview = $(".js-campaign-qa-preview");
    $preview.each(function(i, e) {
      // Load 1st thumbnail with 500ms delay
      if (i === 0) {
        setTimeout(function() {
          $(e).attr("src", $(e).data("src"));
        }, 500 );
      }

      $(e).load(function() {
        //hide spinner
        $(e).prev().hide();
        var $next = $preview.eq(queue++);

        $next.attr("src", $next.data("src"));
        $(e).css('position', 'absolute');
      });
    });
  }

  $(".js-submit-on-change").change(function() {
    $(this).closest('form').submit();
  });

  $('.cursor').focus();
  var hideSpinner;
  $(document)
    .ajaxSend(function(event, xhr, settings) {
      if (settings.url.indexOf("no_spinner=true") === -1) $('#loadingDiv').fadeIn(300);
    })
    .ajaxComplete(function() {
      clearTimeout(hideSpinner);
      hideSpinner = setTimeout(function() {
        $('#loadingDiv').fadeOut(300);
      }, 2000);
    })
    .ajaxStop(function() {
      $('#loadingDiv').fadeOut(300);
    });

  $('.alert.hint button.close, .alert.hint button.complete').click(function (e) {
    $.getJSON($(this).attr('data-url') + '.json', function (data) {  });
    e.preventDefault();
  });

  window.sendEmailSample = function(event) {
    event.preventDefault();

    var $element = $(event.target),
        isEmailTests = !!$element.attr('data-email-tests'),
        $form = $element.closest('.js-email-sample'),
        recipient = $form.find('#email').val(),
        ctaText = $element.text();

    $.ajax({
      url: $form.attr('data-action'),
      type: $form.attr('data-type') || 'PUT',
      data: {email: recipient, emailtests: isEmailTests},
      dataType: 'json',
      beforeSend: function() {
        $element
          .attr('disabled', true)
          .addClass('is-disabled')
          .text('Sending...');
      },
      complete: function() {
        $element
          .attr('disabled', false)
          .removeClass('is-disabled')
          .text(ctaText);
      },
      success: function(data) {
        Validation.succeed(data.message, 5000)
      },
      error: function(data) {
        var message = _.get(data, 'responseJSON.message');
        if (message) Validation.showErrorFlash(message, 8000);
      }
    });
  };

  $('.js-email-sample-submit').on('click', window.sendEmailSample);

  window.reloadAndClearHash = function() {
    window.location = window.location.origin + window.location.pathname + window.location.search;
  };

  /******************/
  /* Copy Me Button */
  /******************/
  function copyMeButton(selector) {
    var elements = $(selector);
    if (!elements.length) return;

    _.forEach(elements, function(element) {
      var el = $(element);
      var clip = new Clipboard(element);

      el.tooltip({ trigger: 'manual' });

      clip.on('success', function(e) {
        var element = $(e.trigger);
        var originalTitle = element.attr('data-original-title') || "";
        var copiedTitle = element.attr('data-copied-label') || "Copied!";
        if (copiedTitle != "false") {
          element
            .attr('data-original-title', copiedTitle)
            .tooltip('show');

          element
            .attr('data-original-title', originalTitle);

          var timer = setTimeout(function() {
            element.tooltip('hide');
          }, 1000);
        }
      });

      el.on({
        mouseover: function() {
          $(this).tooltip('show');
        },
        mouseout: function() {
          $(this).tooltip('hide');
        }
      });
    });
  };

  copyMeButton(".js-copy");

  /**
   * $.fn.serializeFormToJSON is a variant of existing $.serialize method which,
   * instead of encoding form elements to string, converts form elements to a valid JSON object.
   * Form should have inputs with names like name="some[sub][sub][sub][name]"
   */
  $.fn.serializeFormToJSON = function() {
    var o = {};
    var re = /^(.+)\[(.*)\]$/;
    var a = this.serializeArray();
    var n;

    jQuery.each(a, function() {
      var name = this.name;

      if ((n = re.exec(this.name)) && n[2]) {
        if (o[n[1]] === undefined) {
          o[n[1]] = {};
          o[n[1]][n[2]] = this.value || '';
        } else if (o[n[1]][n[2]] === undefined) {
          o[n[1]][n[2]] = this.value || '';
        } else {
          if (!o[n[1]][n[2]].push) {
            o[n[1]][n[2]] = [o[n[1]][n[2]]];
          }
          o[n[1]][n[2]].push(this.value || '');
        }
      } else {
        if (n && !n[2]) {
          name = n[1];
        }
        if (o[name] !== undefined) {
          if (!o[name].push) {
            o[name] = [o[name]];
          }
          o[name].push(this.value || '');
        } else {
          o[name] = this.value || '';
        }
      }
    });

    return o;
  };

  /**
   * $.fn.highlight is text higlighting jQuery plugin
   * http://johannburkard.de/blog/programming/javascript/highlight-javascript-text-higlighting-jquery-plugin.html
   */
  $.fn.highlight = function(pat) {
    function innerHighlight(node, pat) {
      var skip = 0;
      if (node.nodeType == 3) {
        var pos = node.data.toUpperCase().indexOf(pat);
        pos -= (node.data.substr(0, pos).toUpperCase().length - node.data.substr(0, pos).length);
        if (pos >= 0) {
          var spannode = document.createElement('span');
          spannode.className = 'App-highlight';
          var middlebit = node.splitText(pos);
          var endbit = middlebit.splitText(pat.length);
          var middleclone = middlebit.cloneNode(true);
          spannode.appendChild(middleclone);
          middlebit.parentNode.replaceChild(spannode, middlebit);
          skip = 1;
        }
      } else if (node.nodeType == 1 && node.childNodes && !/(script|style)/i.test(node.tagName)) {
        for (var i = 0; i < node.childNodes.length; ++i) {
          i += innerHighlight(node.childNodes[i], pat);
        }
      }
      return skip;
    }
    return this.length && pat && pat.length ? this.each(function() {
      innerHighlight(this, pat.toUpperCase());
    }) : this;
  };

  Utils.trackGAClicks();
});
