/**
 * @prettier
 */

import { getUrlSpaceParameter, publicUrlOptionsHost } from 'utils/etc'
/* -----------------------------
    Helper components
  ----------------------------- */
function FakeContent({ children, simpleLayout }) {
  return (
    <div className="Previews-view-setup-preset-device-content-wrapper">
      {!simpleLayout && (
        <div className="Previews-view-setup-preset-device-content is-top" />
      )}
      {children}
      {!simpleLayout && (
        <div className="Previews-view-setup-preset-device-content is-bottom" />
      )}
    </div>
  )
}

/* -----------------------------
    UI component (dumb): requires ViewSetup and ViewPreset
  ----------------------------- */
export class ViewSetupPreview extends React.PureComponent {
  static propTypes = {
    className: PT.string,
    simpleLayout: PT.bool,
    platformType: PT.string,
    ViewSetup: PT.object.isRequired,
    ViewPreset: PT.object.isRequired,
    autoLoad: PT.oneOf(['false', 'true']),
  }

  static defaultProps = {
    className: '',
    simpleLayout: false,
    platformType: 'desktop',
    autoLoad: 'true',
  }

  applyIframeScripts = iframe => {
    UI.previewSubscribe('offer_loaded', data => {
      UI.previewPublish('preview_styles', iframe.name, { remove_preview_styles: true })
    })
  }

  renderPreviewIframe(component) {
    return this.props.autoLoad === 'true' ? component : null
  }

  get localizationParams() {
    const { localizationOverride } = this.props

    return localizationOverride ? { localization_override: localizationOverride } : {}
  }

  get langFromParams() {
    const urlParams = new URLSearchParams(window.location.search)
    const lang = urlParams.get('language')

    return lang
  }

  get isSocialShare() {
    const {
      ViewSetup: { id },
    } = this.props

    return id === 'social_sharing'
  }

  get nonEmbeddablePreviewAttrs() {
    const { ViewPreset, ViewSetup, platformType, currentSelectedLanguage } = this.props

    const splitTestVariantIds = ViewPreset.split_test_variant_ids || []
    const language = currentSelectedLanguage || this.langFromParams

    const params = {
      preset_slug: ViewPreset.cached_slug,
      key: this.campaignPublicKey,
      user_agent: platformType,
      selected_split_test_variant_ids: splitTestVariantIds,
      ...this.localizationParams,
      ...(language && { language }),
    }

    const sanitizedParams = _.pickBy(params, _.identity)
    const siteCachedSlug = this.props.siteCachedSlug || window.Site.cached_slug
    let src = ''

    if (this.isSocialShare) {
      src = `${publicUrlOptionsHost()}${Routes.social_sharing_site_campaign_previews_path(
        siteCachedSlug,
        getUrlSpaceParameter(window.location.href),
        this.campaignId,
        sanitizedParams,
      )}`
    } else {
      src = `${publicUrlOptionsHost()}${Routes.site_campaign_preview_path(
        siteCachedSlug,
        getUrlSpaceParameter(window.location.href),
        this.campaignId,
        ViewSetup.id,
        sanitizedParams,
      )}`
    }

    const name = `${ViewSetup.id}_${ViewPreset.cached_slug}_${splitTestVariantIds[0]}`

    return { src, name }
  }

  get campaignId() {
    return _.get(this.props.ViewSetup, 'campaign_id', _.get(this.props.Campaign, 'id'))
  }

  get campaignPublicKey() {
    const previewUrl = _.get(this.props.ViewPreset, 'preview_url', '')
    const match = previewUrl.match(/key=([a-z0-9]+)/i) || []

    return match[1] || _.get(this.props.Campaign, 'public_key')
  }

  render() {
    const {
      ViewSetup,
      ViewPreset,
      simpleLayout,
      platformType,
      className,
      currentSelectedLanguage,
    } = this.props

    const isViewSetupEmbeddable = ViewSetup['embeddable_view']
    const isViewSetupEmail = ViewSetup['email_view']
    const isDesktopPreviewMode = platformType === 'desktop' || this.isSocialShare
    const siteCachedSlug = this.props.siteCachedSlug || window.Site.cached_slug
    const language = currentSelectedLanguage || this.langFromParams

    const topWrapperClassName = `
        Previews
        ${platformType}
        ${className}
        ${simpleLayout ? 'is-simple-layout' : ''}
        ${isDesktopPreviewMode ? 'is-desktop-mode' : `is-${platformType}-mode`}
      `

    const viewSetupClasses = `
        Previews-view-setup js-cover
        ${ViewSetup.responsive ? 'is-responsive' : 'is-non-responsive'}
        ${isViewSetupEmbeddable ? 'is-embeddable' : 'is-selfhosted'}
        ${isViewSetupEmail ? 'is-email' : 'is-web'}
      `

    const embeddable_iframe_src =
      publicUrlOptionsHost() +
      Routes.cover_site_campaign_preview_path(
        siteCachedSlug,
        getUrlSpaceParameter(window.location.href),
        this.campaignId,
        ViewSetup.id,
        {
          key: this.campaignPublicKey,
          preset_slug: ViewPreset.cached_slug,
          user_agent: platformType,
          use_fake_layout: !simpleLayout,
          selected_split_test_variant_ids: ViewPreset.split_test_variant_ids,
          ...this.localizationParams,
          ...(language && { language }),
        },
      )

    const commonIframeAttrs = {
      ref: this.applyIframeScripts,
    }

    if (isDesktopPreviewMode && !isViewSetupEmail && simpleLayout) {
      const { scaleIframe, nodeIframe, ViewSetup } = this.props
      const height = $(nodeIframe).height() / scaleIframe
    }

    const embeddableIframeAttrs = {
      name: 'preview-embeddable-cover',
      className: 'js-preview-embeddable-cover Previews-view-setup-preset-cover-iframe',
      src: embeddable_iframe_src,
      'data-testid': 'ac-campaign-preview-iframe',
    }
    const nonEmbeddableIframeAttrs = {
      className: 'js-campaign-preview Previews-view-setup-preset-cover-iframe',
      width: '100%',
      height: '100%',
      frameBorder: '0',
      'data-testid': 'ac-campaign-preview-iframe',
    }

    const noFramedLayout = isViewSetupEmbeddable ? (
      <iframe {...commonIframeAttrs} {...embeddableIframeAttrs} />
    ) : (
      <iframe
        {...commonIframeAttrs}
        {...nonEmbeddableIframeAttrs}
        {...this.nonEmbeddablePreviewAttrs}
      />
    )

    const browserFramedLayout = (
      <div className="Previews-view-setup-preset-wrapper">
        <div className="Previews-view-setup-preset-device-body">
          <div className="Previews-view-setup-preset-device-display">
            {isViewSetupEmbeddable
              ? this.renderPreviewIframe(
                  <iframe {...commonIframeAttrs} {...embeddableIframeAttrs} />,
                )
              : this.renderPreviewIframe(
                  <FakeContent simpleLayout={simpleLayout}>
                    <iframe
                      {...commonIframeAttrs}
                      {...nonEmbeddableIframeAttrs}
                      {...this.nonEmbeddablePreviewAttrs}
                    />
                  </FakeContent>,
                )}
          </div>
        </div>
      </div>
    )

    return (
      <div className={topWrapperClassName}>
        <div className={viewSetupClasses}>
          {simpleLayout ? this.renderPreviewIframe(noFramedLayout) : browserFramedLayout}
        </div>
      </div>
    )
  }
}
