/**
 * @prettier
 */

window.preview_events = {}

window.UI = {
  ALPHABET: 'abcdefghijklmnopqrstuvwxyz',
  AB_TEST_VARIANTS_LIMIT: 10,

  initCopyLink(selector) {
    const elements = $(selector)

    if (!elements.length) {
      return
    }

    return _.forEach(elements, function (element) {
      const el = $(element)
      const clip = new Clipboard(element)

      el.tooltip({ trigger: 'manual' })

      clip.on('success', function (e) {
        element = $(e.trigger)
        const originalTitle = element.attr('data-original-title') || ''
        const copiedTitle = element.attr('data-copied-label') || 'Copied!'

        if (copiedTitle !== 'false') {
          element.attr('data-original-title', copiedTitle).tooltip('show')
          element.attr('data-original-title', originalTitle)
          setTimeout(() => element.tooltip('hide'), 1000)
        }
      })

      return el.on({
        mouseover() {
          return $(this).tooltip('show')
        },
        mouseout() {
          return $(this).tooltip('hide')
        },
      })
    })
  },

  isImageUrl(url) {
    const urlRegExp = /^((https?|ftp):)?\/\/.*(jpeg|jpg|png|gif|bmp|svg)(\?.*)?$/i

    return urlRegExp.test(url)
  },

  path: location.pathname,

  requestPath(path = this.path) {
    return `/internal${path}`
  },

  hub: $('<div></div>'),

  publish(name, data) {
    if (window.console) {
      console.log('Publish event: ' + name)
    }

    this.hub.trigger(name, data)
  },

  subscribe(name, callback) {
    this.hub.bind(name, function (event, data) {
      callback(data)

      return true
    })
  },

  unsubscribe(name) {
    this.hub.unbind(name)
  },

  preview_send(json, target, targetElement) {
    if (typeof target === 'undefined') {
      throw new Error('You must supply a target as a string')
    }

    if (typeof targetElement === 'undefined') {
      targetElement = window.parent
    }

    targetElement.postMessage(JSON.stringify(json), target)
  },

  removePreviewEvent(name) {
    if (name) {
      window.removeEventListener('message', window.preview_events[name], false)
    } else {
      _.each(_.values(window.preview_events), event =>
        window.removeEventListener('message', event, false),
      )
    }
  },

  previewPublish(name, iframe_name, data) {
    const msg_data = {
      type: name,
      iframe_name,
      data: data || {},
    }

    return this.preview_send(msg_data, '*', window.frames[iframe_name])
  },

  previewSubscribe(name, callback) {
    const receiveMessage = function (e) {
      // TODO: check e.origin
      try {
        const message = JSON.parse(e.data)

        if (message.type === name) {
          return callback(message)
        }
      } catch {
        // empty
      }
    }

    if (window.addEventListener) {
      window.preview_events[name] = receiveMessage
      window.addEventListener('message', receiveMessage, false)
    } else {
      window.attachEvent('onmessage', receiveMessage) // IE 8
    }
  },

  urlParams: {},
}

window.location.search.replaceAll(/[?&]+([^=&]+)=([^&]*)/gi, function (str, key, value) {
  this.UI.urlParams[decodeURIComponent(key)] = value
})

$(function () {
  // Append browser vendor as a classname to html tag
  if (document.all && window.XMLHttpRequest) {
    $('html').addClass('browser-ie')
  } else if (navigator.userAgent.match(/firefox/i)) {
    $('html').addClass('browser-firefox')
  }
})
