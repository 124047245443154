/**
 * @prettier
 */
/* eslint-disable import/newline-after-import */

import pluralize from 'pluralize'

import { getUrlSpaceParameter } from 'utils/etc'
;(function (Wizard, $) {
  let expandOnFirstVisit
  const self = Wizard

  const todoContent = function () {
    if ($('[data-wizard-tofix-issue]').length) {
      return `
        <p>Hover over the item marked with a red cross to see its full description and why it is required for launch.</p>
      `
    } else {
      return `
        <p>Congrats! Now you can Launch the campaign and start monitoring its performance.</p>
        <a
          href= "${Routes.launch_form_site_campaign_site_path(
            self.siteId,
            getUrlSpaceParameter(window.location.pathname),
            self.campaignId,
          )}"
          class='base-btn is-small is-success mts'
          data-wizard-launch
        >
          Launch Campaign Now
        </a>
      `
    }
  }

  const todoTitle = function () {
    if ($('[data-wizard-tofix-issue]').length) {
      return `${pluralize('item', $('[data-wizard-tofix-issue]').length)} to fix`
    } else {
      return 'Ready For Launch!'
    }
  }

  const subscribes = {}

  self.shownWizard = false

  const setShownWizard = function (state) {
    self.shownWizard = state
    self.publish('update_shown_wizard', state)
  }

  self.publish = (name, data) => subscribes[name] && subscribes[name](data)

  self.subscribe = (name, callback) => (subscribes[name] = callback)

  self.unsubscribe = name => delete subscribes[name]

  self.init = function () {
    const container = $('.js-campaign-wizard-container')

    self.siteId = container.data('site-id') || Site.id
    self.campaignId = container.data('campaign-id') || Campaign.id
    self.campaignPresetName =
      container.data('campaign-preset-name') || Campaign.preset_name

    $.get(
      Routes.wizard_site_campaign_path(self.siteId, '', self.campaignId),
      { no_spinner: true },
      function (data) {
        if ($.trim(data).length) {
          container.html(data)
          self.asyncInit()
          setShownWizard(true)
        } else {
          setShownWizard(false)
        }
      },
    )
  }

  self.hideWizard = function () {
    $('[data-wizard-container]').addClass('is-hidden')
    Utils.trackGAEvent('CampaignWizard', 'Hide Wizard', self.campaignPresetName)
  }

  self.turnOffWizard = () =>
    $.ajax({
      url: Routes.internal_user_path(),
      type: 'PATCH',
      data: {
        user: {
          show_campaign_wizard: '0',
        },
      },

      success: data => {
        self.hideWizard()
      },
    })

  self.showConfirmation = () =>
    UIkit.UIModalConfirmation.show({
      children:
        "Pre-launch Checklist won't show up for you anymore. You can always re-activate it in your User Settings.",
      onPrimaryAction() {
        self.turnOffWizard()
        setShownWizard(false)
        UIkit.UIModalConfirmation.hide()
      },
      onCloseModal() {
        UIkit.UIModalConfirmation.hide()
      },
    })

  self.asyncInit = function () {
    initializeExtra()
    expandIfErrorsOnLaunch()
    setTimeout(() => expandOnFirstVisit(), 700)
    Utils.trackGAEvent('CampaignWizard', 'Shown', self.campaignPresetName)

    $('.js-wizard-hide-button').on('click', () => self.showConfirmation())

    $('[data-wizard-step-link]').on('click', function () {
      expand($(this).data('wizard-step-link'))
      Utils.trackGAEvent(
        'CampaignWizard',
        'Expand Wizard (List Items)',
        self.campaignPresetName,
      )
    })

    $('[data-wizard-toggle]').on('click', toggle)

    $('[data-wizard-todo-item]').on({
      mouseenter() {
        $('[data-wizard-todo-item]').removeClass('is-hover')
        $(this).addClass('is-hover')
        $('[data-wizard-todo-title]').text('About Item')
        $('[data-wizard-todo-content]').html(
          $(this).find('[data-wizard-todo-description]').data('wizard-todo-description'),
        )
      },
      mouseleave() {
        initializeExtra()
      },
    })

    $('[data-wizard-collapse]').on('click', () => collapse())
  }

  self.reload = function () {
    collapse()
    $('[data-wizard-container]').addClass('is-loading')
    self.init()
    Utils.trackGAEvent('CampaignWizard', 'Reload', self.campaignPresetName)
  }

  self.showOverlayFor = function ($element) {
    if (!$element.length) {
      return
    }
    $element.css('position', 'relative')
    const $overlay = $(
      "<div data-wizard-overlay class='wizard-overlay is-inactive is-hidden' />",
    )

    if (!$('[data-wizard-overlay]').length) {
      $overlay.appendTo($element)
    }
    $('body').animate({ scrollTop: $overlay.offset().top - 100 }, 500)
    setTimeout(() => $overlay.removeClass('is-hidden'), 100)
    setTimeout(() => $overlay.removeClass('is-inactive'), 500)
    setTimeout(() => $overlay.addClass('is-inactive'), 900)
    setTimeout(() => $overlay.removeClass('is-inactive'), 1300)
    setTimeout(() => $overlay.addClass('is-hidden'), 1700)
    setTimeout(() => $overlay.remove(), 2000)
  }

  // Private
  // -------
  const initializeExtra = function () {
    $('[data-wizard-todo-title]').text(todoTitle())
    $('[data-wizard-todo-content]').html(todoContent())
  }

  const expand = function (step) {
    $('[data-wizard]').addClass('is-active')
    $('[data-wizard-toggle]').addClass('is-active')
    if (step) {
      self.showOverlayFor($(`[data-wizard-area='${step}']`))
    }
    // TODO: move to user account level settings
    talkableLocalStorage.setItem(`wizard_${self.siteId}_visited`, true)
  }

  const collapse = function () {
    $('[data-wizard]').removeClass('is-active')
    $('[data-wizard-toggle]').removeClass('is-active')
    initializeExtra()
  }

  const toggle = function () {
    if ($('[data-wizard]').hasClass('is-active')) {
      collapse()
      Utils.trackGAEvent(
        'CampaignWizard',
        'Collapse Wizard (Toggle)',
        self.campaignPresetName,
      )
    } else {
      expand()
      Utils.trackGAEvent(
        'CampaignWizard',
        'Expand Wizard (Toggle)',
        self.campaignPresetName,
      )
    }
  }

  const expandIfErrorsOnLaunch = function () {
    if (
      location.pathname ===
        Routes.launch_form_site_campaign_site_path(self.siteId, self.campaignId) &&
      $('[data-wizard-tofix-issue]').length
    ) {
      expand()
      Utils.trackGAEvent(
        'CampaignWizard',
        'Expand Wizard (Errors On Launch)',
        self.campaignPresetName,
      )
      Utils.trackGAEvent(
        'CampaignWizard',
        `Errors on Launch: ${$('[data-wizard-tofix-issue]').length}`,
        self.campaignPresetName,
      )
    }
  }

  expandOnFirstVisit = function () {
    const visited = talkableLocalStorage.getItem(`wizard_${self.siteId}_visited`)

    if (document.querySelector('.js-first-campaign-visit') && !visited) {
      expand()
      Utils.trackGAEvent(
        'CampaignWizard',
        'Expand Wizard (First Campaign Visit)',
        self.campaignPresetName,
      )
    }
  }
})((window.Wizard = window.Wizard || {}), jQuery)
