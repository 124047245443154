/**
 * @prettier
 */

import { observer } from 'mobx-react'
import { reaction } from 'mobx'
import { UIStaticBadge, UIModalConfirmation } from 'talkable-ui-kit'

import { STATIC_WIDGET_GA_CATEGORY } from 'utils/constants'

export const isPollingByCampaign = ({ static_widget, status }) =>
  static_widget === '1' && status === 'Live'

export const StaticWidget = observer(
  class StaticWidget extends React.PureComponent {
    static propTypes = {
      size: PT.string,
      background: PT.string,
      placement: PT.string,
      withBorder: PT.bool,
      showDeactivationModal: PT.bool,
      staticWidgetStore: PT.object.isRequired,
    }

    constructor(props) {
      super(props)
      this.poll = null
      this.tooltipRef = React.createRef()

      this.disposer = reaction(
        () => props.staticWidgetStore.staticWidgetInfo.tooltipText,
        tooltipText => {
          const {
            staticWidgetStore: { tooltipCanBeShown },
          } = props

          tooltipCanBeShown &&
            $(this.tooltipRef.current)
              .tooltip('hide')
              .attr('title', tooltipText)
              .tooltip('fixTitle')
        },
      )
    }

    startPolling = () => {
      const {
        polling,
        staticWidgetStore: { fetchData },
      } = this.props

      this.poll =
        polling &&
        setInterval(() => {
          fetchData()
        }, 5000)
    }

    stopPolling = () => {
      const { polling } = this.props

      polling && clearInterval(this.poll)
    }

    handleClick = e => {
      e?.preventDefault()

      const {
        staticWidgetStore: {
          showModal,
          staticWidgetInfo: { ineligibleReasonsExists },
        },
      } = this.props

      if (ineligibleReasonsExists) {
        showModal()
        this.stopPolling()
      }
    }

    hideModal = e => {
      e?.preventDefault()

      const {
        staticWidgetStore: { hideModal },
      } = this.props

      hideModal()
      this.startPolling()
    }

    onCloseModal = () => {
      this.props.onCloseModal()
      Utils.trackGAEvent(STATIC_WIDGET_GA_CATEGORY, 'hide deactivation modal')
    }

    onPrimaryAction = () => {
      this.props.onPrimaryAction()
      Utils.trackGAEvent(STATIC_WIDGET_GA_CATEGORY, 'proceed to deactivate')
    }

    onSecondaryAction = () => {
      this.props.onSecondaryAction()
      Utils.trackGAEvent(STATIC_WIDGET_GA_CATEGORY, 'hide deactivation modal')
    }

    componentDidMount() {
      const {
        staticWidgetStore: { fetchData },
      } = this.props

      fetchData()
      this.startPolling()
    }

    componentWillUnmount() {
      this.stopPolling()
      this.disposer()
    }

    render() {
      const {
        size,
        background,
        placement,
        withBorder,
        showDeactivationModal,
        staticWidgetStore: {
          isLoading,
          tooltipCanBeShown,
          showStaticWidgetModal,
          tooltipText,
          staticWidgetInfo: { isActive, errorText },
        },
      } = this.props

      const containerClassName = classNames('ac-static-widget', {
        'Campaign-management-header-indicator': placement === 'header',
      })

      return (
        <div className={containerClassName}>
          <div
            ref={this.tooltipRef}
            data-toggle="tooltip"
            data-container="body"
            data-placement="bottom"
            data-html={true}
            title={tooltipCanBeShown ? tooltipText : ''}
            className="ac-sw-badge"
            onClick={this.handleClick}
          >
            <UIStaticBadge
              withBorder={withBorder}
              size={size}
              active={isActive}
              loading={isLoading}
              background={background}
            />
          </div>
          {showStaticWidgetModal && (
            <div className="ac-static-widget-modal">
              <UIModalConfirmation
                title="Could not publish campaign changes"
                onCloseModal={this.hideModal}
                onPrimaryAction={this.hideModal}
                style="one-section"
              >
                <p>
                  The following campaign changes were saved but Talkable could not publish
                  them because they are incompatible with the &quot;Static trigger
                  widget&quot; setting:
                </p>
                <p>{errorText}</p>
              </UIModalConfirmation>
            </div>
          )}
          {showDeactivationModal && (
            <div className="ac-static-widget-deactivation-modal">
              <UIModalConfirmation
                onCloseModal={this.onCloseModal}
                onPrimaryAction={this.onPrimaryAction}
                onSecondaryAction={this.onSecondaryAction}
                style="two-sections"
                primaryButtonText="Yes"
              >
                <p>
                  This will deactivate &quot;Static trigger widget&quot; setting, are you
                  sure?
                </p>
              </UIModalConfirmation>
            </div>
          )}
        </div>
      )
    }
  },
)
