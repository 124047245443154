/**
 * @prettier
 */

import { useState, useRef } from 'react'
import { UIInputSelect } from 'talkable-ui-kit'

import { DEFAULT_LANGUAGE, ALL_LANGUAGES } from 'utils/constants'

interface LanguageSelectorProps {
  languages: Array<strings>
}

interface LanguageSelectorObject {
  label: string
  value: string
}

export const LanguageSelector = ({
  languages,
}): React.PureComponent<LanguageSelectorProps> => {
  const displayNamesRef = useRef(new Intl.DisplayNames(['en'], { type: 'language' }))
  const [currentSelectedLanguage, setCurrentSelectedLanguage] =
    useState(getLanguageFromParam())

  function configureLangObject(lang: string): LanguageSelectorObject {
    return {
      label:
        lang === DEFAULT_LANGUAGE
          ? 'Default'
          : ALL_LANGUAGES.find(l => l.value === lang).label ||
            displayNamesRef.current.of(lang),
      value: lang,
    }
  }

  function getLanguageFromParam(): void {
    const urlParams = new URLSearchParams(window.location.search)
    const lang = urlParams.get('language') || DEFAULT_LANGUAGE

    return configureLangObject(lang)
  }

  const onLanguageSelect = ({ selectedOption, selectedOption: { value } }): void => {
    setCurrentSelectedLanguage(selectedOption)
    setQueryParam('language', value)
  }

  const setQueryParam = (key: string, value: string): void => {
    const url = new URL(window.location)

    url.searchParams.set(key, value)
    window.history.replaceState(null, '', url)
    window.location.reload()
  }

  return (
    <div>
      <UIInputSelect
        className="mrs"
        options={languages.map(lang => configureLangObject(lang))}
        value={currentSelectedLanguage}
        onChange={onLanguageSelect}
      />
    </div>
  )
}
