/**
 * @prettier
 */

export const AB_TESTS_MODAL_CONSTANTS = {
  existingTemplate: {
    headerParams: {
      title: 'Add to existing A/B Test',
      text: 'Do you want to add this campaign to the existing A/B Tests you already have for ',
    },
    footerParams: {
      buttonText: 'Create new A/B Test',
    },
  },
  unsavedChangesTemplate: {
    headerParams: {
      title: 'Warning',
      text: 'You have changes on the page. Do you want to leave without saving them?',
    },
    footerParams: {
      buttonText: 'Leave',
    },
  },
  confirmationTemplateHasWinner: {
    headerParams: {
      title: 'Confirmation',
      text: 'You already have a Winner. Once you mark this variant as a Winner, the A/B Test will stop and only this variant will be shown in your Campaigns.',
    },
    footerParams: {
      buttonText: 'Mark As Winner',
    },
  },
  confirmationTemplateUnmarkWinner: {
    headerParams: {
      title: 'Confirmation',
      text: 'Once you unmark the variant as a Winner, the A/B Test will start again and all variants in this A/B Test will start to be shown in the Campaigns.',
    },
    footerParams: {
      buttonText: 'Unmark As Winner',
    },
  },
  confirmationTemplateMarkWinner: {
    headerParams: {
      title: 'Confirmation',
      text: 'Once you mark this variant as a Winner, the A/B Test will stop and only this variant will be shown in your Campaigns.',
    },
    footerParams: {
      buttonText: 'Mark As Winner',
    },
  },
  createNewTemplate: {
    headerParams: {
      title: 'Create a New Content A/B Test',
    },
  },
  confirmDeletePopup: {
    headerParams: {
      title: 'Confirmation',
    },
    footerParams: {
      buttonText: 'Delete A/B Test',
      buttonType: 'is-danger',
    },
  },
  confirmDeleteVariantPopup: {
    headerParams: {
      title: 'Confirmation',
    },
    footerParams: {
      buttonText: 'Delete Variant',
      buttonType: 'is-danger',
    },
  },
  confirmChooseWinnerPopup: {
    headerParams: {
      title: 'Confirmation',
    },
    footerParams: {
      buttonText: 'Set as Winner',
    },
  },
}

export const TABS_CONFIG = [
  {
    className: 'text',
    text: 'Copy',
    rules(l) {
      return _.includes(['string', 'message'], l.trait) && l.enum_values.length === 0
    },
  },
  {
    className: 'asset',
    text: 'Images',
    rules(l) {
      return l.trait === 'asset' || l.trait === 'share_image'
    },
  },
  {
    className: 'color',
    text: 'Color',
    rules(l) {
      return l.trait === 'color'
    },
  },
  {
    className: 'enum',
    text: 'Configuration',
    rules(l) {
      return l.enum_values.length !== 0
    },
  },
]

export const TIMESTAMPS = [
  {
    value: 86_400,
    dimension: 'day',
  },
  {
    value: 3600,
    dimension: 'hour',
  },
  {
    value: 60,
    dimension: 'minute',
  },
  {
    value: 1,
    dimension: 'second',
  },
]
