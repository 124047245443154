/**
 * @prettier
 */
import { observer, Observer } from 'mobx-react'
import { TransitionGroup } from 'react-transition-group'

import { withMSTDevTools } from 'utils/mst_dev_tools'

import { InfoSidebarContainer } from './info_sidebar_container'
import { InfoSidebarStore } from './info_sidebar_store'

const idInfoSidebar = 'info-sidebar'

const removeInfoSidebarFromDOM = () => {
  if (document.getElementById(idInfoSidebar)) {
    document.body.removeChild(document.getElementById(idInfoSidebar))
  }
}

const infoSidebarStore = withMSTDevTools(InfoSidebarStore.create())

const InfoSidebarPortal = observer(
  class InfoSidebarPortal extends React.Component {
    static propTypes = {
      isFetched: PT.bool,
      fetchData: PT.func,
    }

    constructor() {
      super()

      const sidebar = document.createElement('div')

      sidebar.setAttribute('id', idInfoSidebar)

      this.sidebar = sidebar
    }

    componentDidMount() {
      document.body.appendChild(this.sidebar)
      const { infoSidebarStore, isFetched, data, fetchData } = this.props

      if (isFetched) {
        infoSidebarStore.updateData(data)
      } else {
        if (typeof fetchData === 'function') fetchData()
      }
      Utils.trackGAEvent(infoSidebarStore.gaCategory, 'open')
    }

    componentDidUpdate(prevProps) {
      if (this.props.isFetched && !_.isEqual(prevProps.data, this.props.data)) {
        this.props.infoSidebarStore.updateData(this.props.data)
      }
    }

    componentWillUnmount() {
      Utils.trackGAEvent(this.props.infoSidebarStore.gaCategory, 'close')
      this.props.infoSidebarStore.reset()
      removeInfoSidebarFromDOM()
    }

    render() {
      return ReactDOM.createPortal(this.props.children, this.sidebar)
    }
  },
)

export class InfoSidebarProvider extends React.Component {
  static propTypes = {
    needUseShowProp: PT.bool,
    show: PT.bool,
    toggleShow: PT.func,
    data: PT.object,
    isFetched: PT.bool,
    fetchData: PT.func,
    render: PT.func,
    align: PT.oneOf(['right', 'left']),
    width: PT.string,
  }

  static defaultProps = {
    needUseShowProp: false,
    show: false,
    toggleShow: () => null,
    fetchData: () => null,
    title: () => null,
    isFetched: true,
    align: 'right',
  }

  state = {
    show: false,
  }

  static getDerivedStateFromProps(props) {
    if (props.needUseShowProp && !props.show) {
      return {
        show: false,
      }
    }

    return null
  }

  toggleShow = () => {
    this.setState(
      prevState => ({
        show: !prevState.show,
      }),
      () => {
        this.props.toggleShow()
      },
    )
  }

  onClose = () => {
    this.setState(
      {
        show: false,
      },
      () => {
        this.props.toggleShow()
      },
    )
  }

  componentWillUnmount() {
    removeInfoSidebarFromDOM()
  }

  render() {
    const { title, content, fetchData, data, isFetched, render, align, width } =
      this.props
    const show = this.props.show || this.state.show

    return (
      <div className="inline-flex align-items-center">
        {render(this.toggleShow, show)}
        <TransitionGroup>
          <Observer>
            {() =>
              show && (
                <InfoSidebarPortal
                  infoSidebarStore={infoSidebarStore}
                  isFetched={isFetched}
                  fetchData={fetchData}
                  data={data}
                >
                  <InfoSidebarContainer
                    width={width}
                    infoSidebarStore={infoSidebarStore}
                    title={title}
                    isFetched={isFetched}
                    show={show}
                    onClose={this.onClose}
                    content={content}
                    align={align}
                  />
                </InfoSidebarPortal>
              )
            }
          </Observer>
        </TransitionGroup>
      </div>
    )
  }
}
