/**
 * @prettier
 */

window.ujsUtils = (() => {
  return {
    applyToProps,
  }

  function applyToProps(parentSelector, changer) {
    const components = ReactRailsUJS.findDOMNodes(parentSelector)

    if (!components.length) return
    ReactRailsUJS.unmountComponents(parentSelector)

    const updateProps = node => {
      const oldProps = JSON.parse(node.dataset.reactProps) || {}
      let newProps

      if (_.isFunction(changer)) {
        newProps = changer(oldProps)
      } else if (_.isObject(changer)) {
        newProps = _.merge(oldProps, changer)
      }
      node.dataset.reactProps = JSON.stringify(newProps)
    }

    _.forEach(components, updateProps)

    ReactRailsUJS.mountComponents(parentSelector)
  }
})()
