/**
 * @prettier
 */
import './polyfills'
import $ from 'jquery'
import 'vendor/javascripts/jquery/jquery-migrate-3.3.2'
import 'vendor/javascripts/jquery.query-2.1.7'
import 'vendor/javascripts/jquery-plugins/jquery.tristate'
import 'vendor/javascripts/chosen.jquery'
import 'vendor/javascripts/ajax-chosen'
import 'vendor/javascripts/rails'

import 'bootstrap-sass/js/bootstrap-transition'
import 'vendor/javascripts/bootstrap3.affix'
import 'bootstrap-sass/js/bootstrap-alert'
import 'bootstrap-sass/js/bootstrap-button'
import 'bootstrap-sass/js/bootstrap-dropdown'
import 'bootstrap-sass/js/bootstrap-modal'
import 'bootstrap-sass/js/bootstrap-tab'
import 'vendor/javascripts/jquery.bootstrap.collapse'
import 'vendor/javascripts/bootstrap/bootstrap.tooltip'
import 'vendor/javascripts/bootstrap/bootstrap.popover'

import './widget/spinner'
import './widget/reports/report'
import './widget'
import './global'
import './helpers/local_storage'
import './helpers/ujs_utils'
import './bundle_react'
import './widget/static/utils'
import { Validation } from './widget/app/validation'
import './widget/app/wizard'
import './widget/app/couponlist'
import './widget/referral_approval_actions'

import './application_code'
import './internal/grids'
import './grid_exports'
import './reports_tracking'

window.Validation = Validation

document.addEventListener('DOMContentLoaded', () => {
  if (_.get(window, 'Site.id')) {
    Utils.setCurrentSite(window.Site.cached_slug)
  }

  $('[data-widget]').each(function (i, e) {
    let widget = $(e).data('widget')

    if (window[widget]) window[widget].init()
    if (window.Widget[widget]) window.Widget[widget].init()
  })

  $('.js-staff-member').tooltip({
    title: 'Available to Staff',
    placement: 'bottom',
  })

  $(document).tooltip({
    selector: '[data-toggle="tooltip"], .js-tooltip',
  })

  $('[data-toggle="popover"]').popover()

  $('.js-blocklist-link')
    .on('click', function (e) {
      e.preventDefault()
    })
    .on('confirm:complete', function (e, response) {
      e.preventDefault()
      if (!response) return null
      let data = $(this).data()

      $.ajax({
        type: 'POST',
        url: data.url,
        data: { ip: data.ip, email: data.email },
        success() {
          let $button = $(e.currentTarget)

          $button
            .removeClass('is-danger')
            .addClass('is-disable')
            .removeAttr('data-confirm')
            .text($button.text().replace('Block', 'Blocked'))
          $('.js-blocklist-text').text('Yes')
        },
      })
    })

  autosize($('.js-autosize'))

  $('.js-async-count-container').on('async_count_replaced', function (e) {
    let $this = $(this)

    $this.addClass('badge')
    if ($this.text() !== '0') {
      $this.addClass('badge-info')
    }
    e.stopPropagation()
  })

  function attachToggle() {
    let dropdownOpen = false
    let currentDropdownMenu = null
    let currentTargetButton = null
    let currentOriginalParent = null

    $('.body-toggle').on('click', function (e) {
      e.stopPropagation()

      const $dropdownMenu = $(this).next('.dropdown-menu')
      const $targetButton = $(this)
      const $originalParent = $dropdownMenu.parent()
      const isDropUp = $originalParent.hasClass('dropup')

      if (dropdownOpen && $targetButton.is(currentTargetButton)) {
        currentOriginalParent.append(currentDropdownMenu.detach())

        currentDropdownMenu.css({
          display: 'none',
          position: 'absolute',
          top: 'auto',
          left: 'auto',
          width: 'auto',
        })

        dropdownOpen = false
        currentDropdownMenu = null
        currentTargetButton = null
        currentOriginalParent = null
      } else {
        if ($dropdownMenu.length) {
          const offset = $(this).offset()

          const dropdownHeight = $dropdownMenu.outerHeight()
          const dropdownWidth = $dropdownMenu.outerWidth()

          $dropdownMenu.css({
            display: 'block',
            top: isDropUp
              ? offset.top - dropdownHeight
              : offset.top + $(this).outerHeight(),
            left: isDropUp
              ? offset.left - dropdownWidth + $(this).outerWidth()
              : offset.left,
            width: 'fit-content',
            'z-index': 1000,
          })

          $('body').append($dropdownMenu.detach())

          dropdownOpen = true
          currentDropdownMenu = $dropdownMenu
          currentTargetButton = $targetButton
          currentOriginalParent = $originalParent
        }
      }
    })

    $(document).on('click', function (e) {
      if (dropdownOpen) {
        const $target = $(e.target)

        if (
          !$target.is(currentTargetButton) &&
          !$target.closest(currentDropdownMenu).length
        ) {
          currentOriginalParent.append(currentDropdownMenu.detach())

          currentDropdownMenu.css({
            display: 'none',
            position: 'absolute',
            top: 'auto',
            left: 'auto',
            width: 'auto',
          })

          dropdownOpen = false
          currentDropdownMenu = null
          currentTargetButton = null
          currentOriginalParent = null
        }
      }
    })
  }

  attachToggle()
})
