/**
 * @prettier
 */

import { BaseTextLabelStatus } from 'legacy/widget/app/components/base_text_label_status'
import { dateFromNow } from 'legacy/widget/app/ab_tests/components/modal/ab_tests_modal_utilities'

export class LocalizationItemABStatus extends React.PureComponent {
  renderReadyToRunStatus = () => {
    return <BaseTextLabelStatus status={this.props.data.status} />
  }

  renderInactiveStatus = () => {
    const { became_inactive_at } = this.props.data

    return (
      <div className="Locale-entries-localizations-ab-status-box">
        <BaseTextLabelStatus status={this.props.data.status} />
        <span className="Locale-entries-localizations-ab-status-helper is-gray">{` ${
          became_inactive_at ? `for ${dateFromNow(became_inactive_at)}` : ''
        }`}</span>
      </div>
    )
  }

  renderActiveStatus = () => {
    return <BaseTextLabelStatus status={this.props.data.status} />
  }

  renderFinishedStatus = () => {
    const {
      data: { variants, winner_selected_at },
    } = this.props
    let winnerPosition = UI.ALPHABET.charAt(0)

    variants.forEach((item, index) => {
      if (item.winner) {
        winnerPosition = UI.ALPHABET.charAt(index).toUpperCase()
      }
    })

    return (
      <div className="Locale-entries-localizations-ab-status-box">
        <BaseTextLabelStatus status={this.props.data.status} />
        <span className="Locale-entries-localizations-ab-status-helper">
          <span className="is-green is-bold"> Winner {winnerPosition} </span>
          selected on {dayjs(winner_selected_at).format('MM/DD/YYYY')}
        </span>
      </div>
    )
  }

  state = {
    active: this.renderActiveStatus,
    inactive: this.renderInactiveStatus,
    finished: this.renderFinishedStatus,
    ready: this.renderReadyToRunStatus,
  }

  render() {
    const { status, id } = this.props.data

    return (
      <div data-testid={`ac-ab-localization-status-${id}`}>{this.state[status]()}</div>
    )
  }
}
