/**
 * @prettier
 */

import { REPORTS_GA_CATEGORY } from 'utils/constants'

document.addEventListener('DOMContentLoaded', () => {
  $(document).on('click', '.js-reports input[type=checkbox]', e => {
    Utils.trackGAEvent(REPORTS_GA_CATEGORY, 'checkbox click', e.target.id)

    if (e.target.value.includes('custom_property')) {
      Utils.trackGAEvent(
        REPORTS_GA_CATEGORY,
        'custom_property checkbox click',
        e.target.id,
      )
    }
  })

  $(document).on('click', '.js-reports select', e => {
    Utils.trackGAEvent(REPORTS_GA_CATEGORY, 'select click', e.target.id)

    if (['people_grid_pcp_key1', 'people_grid_pcp_key2'].includes(e.target.id)) {
      Utils.trackGAEvent(
        REPORTS_GA_CATEGORY,
        'select click custom_property filter',
        e.target.id,
      )
    }
  })
})
