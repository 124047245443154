/**
 * @prettier
 */

import { useEffect } from 'react'

export function ViewSetupPreviewEvent(props) {
  useEffect(() => {
    $(window).on('load scroll', function () {
      Widget.CampaignPreviews.renderPreview(props.preview_node_selector)
    })
  }, [])

  return null
}
