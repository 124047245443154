/**
 * @prettier
 */

import { UIIcon } from 'talkable-ui-kit'
import styled from 'styled-components'

import { DEVICE_SWITCHER_GA_CATEGORY } from 'utils/constants'

type setPreviewDeviceModeType = {
  (mode: string): void
}

type platformTypes = 'desktop' | 'tablet' | 'mobile'

interface DeviceSwitcherProps {
  campaignId?: number
  customStyles?: string
  platformOrder: Array<platformTypes>
  platformType: platformTypes
  setPreviewDeviceMode?: setPreviewDeviceModeType
}

const DeviceSwitcherButton = styled.div<{ customStyles: string }>`
  && {
    ${(props): string => props.customStyles}
  }
`

export class DeviceSwitcher extends React.PureComponent<DeviceSwitcherProps> {
  static defaultProps = {
    platformOrder: ['desktop', 'tablet', 'mobile'],
  }

  setPreviewDeviceMode = (mode: platformTypes): void => {
    this.updateDefaultPreviewMode(mode)
    if (this.props.setPreviewDeviceMode) {
      this.props.setPreviewDeviceMode(mode)
    }
  }

  activateMobileMode = (): void => {
    this.setPreviewDeviceMode('mobile')
    Utils.trackGAEvent(DEVICE_SWITCHER_GA_CATEGORY, 'activate mobile mode')
  }

  activateTabletMode = (): void => {
    this.setPreviewDeviceMode('tablet')
    Utils.trackGAEvent(DEVICE_SWITCHER_GA_CATEGORY, 'activate tablet mode')
  }

  activateDesktopMode = (): void => {
    this.setPreviewDeviceMode('desktop')
    Utils.trackGAEvent(DEVICE_SWITCHER_GA_CATEGORY, 'activate desktop mode')
  }

  renderPlatformToggler = (platform: platformTypes): JSX.Element => {
    switch (platform) {
      case 'desktop':
        return this.renderDesktopToggler()
      case 'tablet':
        return this.renderTabletToggler()
      case 'mobile':
        return this.renderMobileToggler()
    }
  }

  updateDefaultPreviewMode = (platform: platformTypes): void => {
    if (this.props.campaignId) {
      const prevParsedModes = JSON.parse(localStorage.getItem('previewMode') || '')

      localStorage.setItem(
        'previewMode',
        JSON.stringify({
          ...prevParsedModes,
          [this.props.campaignId]: platform,
        }),
      )
    }
  }

  renderDesktopToggler = (): JSX.Element => {
    const platformTogglerClassName = classNames('btn js-previews-platform-toggler', {
      active: this.props.platformType === 'desktop',
    })

    return (
      <DeviceSwitcherButton
        customStyles={this.props.customStyles || ''}
        key="Desktop"
        className={platformTogglerClassName}
        aria-label="Desktop"
        data-balloon-pos="down"
        data-platform="desktop"
        onClick={this.activateDesktopMode}
      >
        <UIIcon name="desktop" className="mrn" width="26" height="24" />
      </DeviceSwitcherButton>
    )
  }

  renderTabletToggler = (): JSX.Element => {
    const platformTogglerClassName = classNames('btn js-previews-platform-toggler', {
      active: this.props.platformType === 'tablet',
    })

    return (
      <DeviceSwitcherButton
        customStyles={this.props.customStyles || ''}
        key="Tablet"
        className={platformTogglerClassName}
        aria-label="Tablet"
        data-balloon-pos="down"
        data-platform="tablet"
        onClick={this.activateTabletMode}
      >
        <UIIcon name="tablet" className="mrn" width="24" height="26" />
      </DeviceSwitcherButton>
    )
  }

  renderMobileToggler = (): JSX.Element => {
    const platformTogglerClassName = classNames('btn js-previews-platform-toggler', {
      active: this.props.platformType === 'mobile',
    })

    return (
      <DeviceSwitcherButton
        customStyles={this.props.customStyles || ''}
        key="Mobile"
        className={platformTogglerClassName}
        aria-label="Mobile"
        data-balloon-pos="down"
        data-platform="mobile"
        onClick={this.activateMobileMode}
      >
        <UIIcon name="mobile" className="mrn" width="24" height="26" />
      </DeviceSwitcherButton>
    )
  }

  render(): JSX.Element {
    const { platformOrder } = this.props

    return (
      <div className="device-switcher">
        <div className="btn-group">
          {platformOrder.map(platform => this.renderPlatformToggler(platform))}
        </div>
      </div>
    )
  }
}
