/**
 * @prettier
 */

import { delayForRetry } from 'utils/etc'
import { GRID_EXPORT_GA_CATEGORY } from 'utils/constants'

$(function () {
  setTimeout(function () {
    $('.js-generating-report-send').show()
  }, 3000)

  let gridExportUrl = $('.js-grid-export-async').data('url')

  if (gridExportUrl) {
    const getStatusExportUrl = function (count) {
      const delay = delayForRetry(count)

      setTimeout(function () {
        $.get(gridExportUrl, { no_spinner: true }, function (data) {
          if (data.result.grid_export.total_records_count) {
            $('.js-grid-export-async-records_count_info').html(
              data.result.grid_export.records_count_info,
            )
            $('.js-grid-export-async-pagination').html(data.result.grid_export.pagination)
          } else {
            getStatusExportUrl(count + 1)
          }
        }).fail(function (xhr) {
          notifyDevelopers({
            name: 'Error during ping for getStatusExport',
            message: 'Ajax failure',
            xhr,
            custom: {
              grid_export: gridExportUrl,
              retries: count,
              waiting_time_in_minutes: (count * 3) / 60,
            },
          })

          const errors = _.get(xhr, 'responseJSON.result.errors', {})
          const errorText = _.values(errors).join(' ')

          if (errorText) {
            UINotifications.add({
              text: errorText,
              type: 'error',
              delay: 10_000,
            })
          } else {
            Utils.trackGAEvent(
              GRID_EXPORT_GA_CATEGORY,
              'Error during ping for getStatusExport',
              window.location.href,
            )
            getStatusExportUrl(count + 1)
          }
        })
      }, delay)
    }

    getStatusExportUrl(0)
  }

  let gridExportSendEmail = $('.js-update-grid-export-send-email')
  let gridExportSendEmailUrl = gridExportSendEmail.data('url')

  if (gridExportSendEmailUrl) {
    const getStatusExportSendEmailUrl = function (count) {
      const delay = delayForRetry(count)

      setTimeout(function () {
        $.get(gridExportSendEmailUrl, { no_spinner: true }, function (data) {
          if (_.get(data, 'result.grid_export.page_finished_at')) {
            let newUrl =
              window.location.origin +
              window.location.pathname +
              '?' +
              window.queryString.stringify(
                _.merge(window.queryString.parse(window.location.search), {
                  geid: _.get(data, 'result.grid_export.id'),
                }),
              )

            window.location.replace(newUrl)
          } else {
            getStatusExportSendEmailUrl(count + 1)
          }
        }).fail(function (xhr) {
          notifyDevelopers({
            name: 'Error during ping for getStatusExportSendEmail',
            message: 'Ajax failure',
            xhr,
            custom: {
              grid_export: gridExportSendEmailUrl,
              retries: count,
              waiting_time_in_minutes: (count * 3) / 60,
            },
          })
          const errors = _.get(xhr, 'responseJSON.result.errors', {})
          const errorText = _.values(errors).join(' ')

          if (errorText) {
            UINotifications.add({
              text: errorText,
              type: 'error',
              delay: 10_000,
            })
          } else {
            Utils.trackGAEvent(
              GRID_EXPORT_GA_CATEGORY,
              'Error during ping for getStatusExportSendEmail',
              window.location.href,
            )
            getStatusExportSendEmailUrl(count + 1)
          }
        })
      }, delay)
    }

    getStatusExportSendEmailUrl(0)

    gridExportSendEmail.change(function () {
      let checked = this.checked

      gridExportSendEmail.prop('disabled', true)
      $.ajax({
        type: 'PUT',
        url: gridExportSendEmailUrl,
        data: { grid_export: { send_email: checked } },
        success(data) {
          UINotifications.add({
            text: data.result.message,
            type: checked ? 'success' : 'notice',
          })
        },
        error(error) {
          UINotifications.add({
            text:
              error.responseJSON.result.error_message ||
              _.values(_.get(error, 'responseJSON.result.errors', {})).join(' '),
            type: 'error',
            delay: 10_000,
          })
        },
        complete() {
          gridExportSendEmail.prop('disabled', false)
        },
      })
    })
  }

  $('.js-grid-export-export-csv').click(function (event) {
    event.preventDefault()

    $.post($(this).data('url'), {
      previous_grid_attributes: $(this).data('grid-attributes'),
      new_grid_attributes: $('form').serialize(),
      grid_class: $(this).data('grid-class'),
    })
      .done(function (data) {
        UINotifications.add({
          text: data.result.message,
          type: 'success',
          delay: 10_000,
        })
      })
      .fail(function (error) {
        console.log(error)
        UINotifications.add({
          text:
            error.responseJSON.result.error_message ||
            _.values(_.get(error, 'responseJSON.result.errors', {})).join(' '),
          type: 'error',
          delay: 10_000,
        })
      })
  })
})
