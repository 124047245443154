/**
 * @prettier
 */
/* eslint-disable import/newline-after-import */

import { getUrlSpaceParameter } from 'utils/etc'
;(function (CouponList, $) {
  const self = CouponList

  return (self.init = function () {
    // Make use of native JS FormData to send CSV file along with other
    // form fields via AJAX
    if (typeof FormData === 'undefined' || FormData === null) {
      return
    }

    $('.js-coupon-list-upload-fields').show()

    $('.js-coupon-list-form').submit(function (e) {
      e.preventDefault()

      const data = new FormData(this)
      const space = getUrlSpaceParameter(window.location.pathname)

      data.append('space', space)
      const nameUploadFile = 'coupon_list[upload_file]'
      const inputFile = "input[name='" + nameUploadFile + "']"
      const valueUploadFile =
        $(this).find(inputFile).length > 0 && $(this).find(inputFile).prop('files')[0]

      if (valueUploadFile) {
        data.set(nameUploadFile, valueUploadFile)
      } else {
        data.delete(nameUploadFile)
      }

      return $.ajax({
        url: $(this).attr('action'),
        data,
        type: $(this).data('method'),
        contentType: false,
        processData: false,
      })
        .done(function (data, textStatus, jqXHR) {
          if ($('.js-coupon-list-form').data('action-type') === 'delete_coupons') {
            const timeout = 4000

            Validation.showSuccessFlash(
              'Deletion started. You will be notified when it finishes.',
              timeout,
            )
            setTimeout(() => {
              window.location = Routes.site_coupon_lists_path(Site.cached_slug, space)
            }, timeout)
          } else {
            return (window.location = Routes.site_coupon_list_path(
              Site.cached_slug,
              space,
              data.result.coupon_list.id,
            ))
          }
        })
        .fail(function (xhr, textStatus, errorThrown) {
          let parsedErrors

          if (xhr.responseJSON) {
            parsedErrors = Validation.fail(xhr.responseJSON.result.errors)
          }
          if (parsedErrors?.expires_at) {
            ujsUtils.applyToProps('.js-new-coupon-list-rangepicker-owner', props =>
              _.update(props, 'inputProps.errors', _.constant(parsedErrors.expires_at)),
            )
          }
        })
        .always(() => $('.js-new-coupon-list-form-submit-btn').attr('disabled', false))
    })
  })
})((window.CouponList = window.CouponList || {}), jQuery)
