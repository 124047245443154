/**
 * @prettier
 */

import { TIMESTAMPS } from 'legacy/widget/app/ab_tests/components/modal/ab_tests_modal_constants'

export const dateFromNow = (createdAt, shortForm) => {
  let dimensionValue

  TIMESTAMPS.forEach(item => {
    const timestampTemp = {
      value: Math.floor(
        (Number(dayjs().unix()) - Number(dayjs(createdAt).unix())) / item.value,
      ),
      dimension: item.dimension,
    }

    if (!dimensionValue && timestampTemp.value) {
      dimensionValue = timestampTemp
    }
  })
  if (!shortForm) {
    return `${_.get(dimensionValue, 'value')} ${_.get(dimensionValue, 'dimension')}${
      _.get(dimensionValue, 'value') === 1 ? '' : 's'
    }`
  } else {
    return `${_.get(dimensionValue, 'value')} ${_.get(
      dimensionValue,
      'dimension',
      '',
    ).charAt(0)}`
  }
}
