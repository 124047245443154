/**
 * @prettier
 */

import { REPORTS_GA_CATEGORY } from 'utils/constants'
import { entryDate } from 'utils/etc'

let startDate = null
let endDate = null

document.addEventListener('DOMContentLoaded', function () {
  UI.subscribe('UIRangePicker_DidMount', function (data) {
    const liveCampaignsCheckboxWrapper = document.querySelector(
      '.js-live-campaigns-checkbox-wrapper',
    )

    if (liveCampaignsCheckboxWrapper) {
      startDate = _.get(data, 'from')
      endDate = _.get(data, 'to')
      liveCampaignsCheckboxWrapper.classList.remove('hide')
      onReportRangePickerChange({
        firstLoad: true,
      })
    }
  })

  function onReportRangePickerChange(data) {
    $('.js-campaigns-filter').each(function (index, widget) {
      const $widget = $(widget)
      const $slugSelect = $widget.find('.js-campaign-slug-select')
      const $slugSelectOrigin = $widget.find('.js-campaign-slug-select').clone()

      filterCampaigns({
        $widget,
        $slugSelectOrigin,
        $slugSelect,
        firstLoad: data.firstLoad,
      })
    })
  }

  function filterCampaigns(data) {
    const $widget = data.$widget
    const $slugSelect = data.$slugSelect
    const $newSlugSelect = data.$slugSelectOrigin.clone()
    const $liveCampaignsCheckbox = $widget.find('.js-live-campaigns-checkbox')[0]

    $widget.find('.js-tag-select :selected').map(function () {
      const filterName = $(this).parent().data('filterName')

      if ($(this).val()) {
        $newSlugSelect
          .find('option:not([data-' + filterName + '~=' + $(this).val() + '])')
          .remove()
      }
    })

    if ($liveCampaignsCheckbox.checked && startDate) {
      $newSlugSelect.find('option').each(function (i, option) {
        const $option = $(option)
        const liveFrom = $option.data('live-from')
        const liveTo = $option.data('live-to')

        const isCampaignValid = entryDate({
          startDate,
          endDate,
          liveFrom,
          liveTo,
        })

        if (isCampaignValid) {
          if (!data.firstLoad) {
            $(option).attr('selected', true)
          }
        } else {
          $option.remove()
        }
      })
    }

    // Remove `optgroup`s without `option`s
    $newSlugSelect.find('optgroup:not(:has(*))').remove()

    $slugSelect.html($newSlugSelect.html()).scrollTop(0)
  }

  $('.js-campaigns-filter').each(function (index, widget) {
    const $widget = $(widget)
    const $slugSelect = $widget.find('.js-campaign-slug-select')
    const $liveCampaignsCheckbox = $widget.find('.js-live-campaigns-checkbox')
    const $slugSelectOrigin = $widget.find('.js-campaign-slug-select').clone()
    const $tagSelects = $widget.find('.js-tag-select')
    const $submitOnChange = $widget.data('submit-on-change')

    $tagSelects.chosen({
      width: '100%',
      disable_search_threshold: 5,
      search_contains: true,
    })

    if ($liveCampaignsCheckbox) {
      UI.subscribe('UIRangePicker_onHideModal', function (data) {
        startDate = _.get(data, 'from')
        endDate = _.get(data, 'to')
        filterCampaigns({
          $widget,
          $slugSelectOrigin,
          $slugSelect,
        })
      })

      $liveCampaignsCheckbox.on('change', function () {
        filterCampaigns({
          $widget,
          $slugSelectOrigin,
          $slugSelect,
        })
        Utils.trackGAEvent('Reports', 'Live campaigns checkbox', this.checked)
      })
    }

    filterCampaigns({
      $widget,
      $slugSelectOrigin,
      $slugSelect,
    })

    // Select campaigns
    $tagSelects.change(function () {
      filterCampaigns({
        $widget,
        $slugSelectOrigin,
        $slugSelect,
      })

      if ($submitOnChange) {
        showSpinner()
        this.form.submit()
      }
    })

    // Reset all campaign filters
    $widget.find('.js-filter-reset').click(function (event) {
      event.preventDefault()

      // Deselect selected type / tag name / status
      $tagSelects.each(function (i, el) {
        $(el).val($(el).find('option:first').val()).change()
      })

      // Deselect selected campaigns
      if ($slugSelect[0]) {
        // other methods return
        // <option data-appearance="inline" selected="" value="12">
        // when we use .html()

        $slugSelect.find('option:selected').removeAttr('selected')
        $slugSelectOrigin.find('option:selected').removeAttr('selected')
      }

      $tagSelects.trigger('chosen:updated')
      Utils.trackGAEvent(REPORTS_GA_CATEGORY, 'Reset campaigns filter click')
    })
  })

  $('.js-clear-range').click(function (event) {
    $(this).parent().find('.date_filter').val('')

    return false
  })

  $('select.period_type_filter').on('change', function () {
    period_type_filter_changed($(this))
  })
  period_type_filter_changed()

  $('.js-async-metric-trend-loader').each(function (_, element) {
    element = $(element)
    const links = element.data('href')
    const options = {}

    if (element.data('humanize')) {
      options.humanize = true
    }

    $.get(links.current, options, function (current_data) {
      $.get(links.previous, options, function (previous_data) {
        const MIN_HUMANIZE_VALUE = 1_000_000
        const metrics = [
          { value: previous_data, selector: element.find('.js-previous-metric-value') },
          { value: current_data, selector: element.find('.js-current-metric-value') },
        ]

        $.each(metrics, function (k, metric) {
          const value = metric.value
          const formatted_value =
            (value.plain >= MIN_HUMANIZE_VALUE && value.humanized) || value.formatted

          metric.selector.html(formatted_value)

          if (value.humanized !== value.formatted && value.plain > MIN_HUMANIZE_VALUE) {
            metric.selector.data('title', value.formatted)
          }
        })

        if (current_data.plain !== previous_data.plain) {
          element
            .find('.js-metric-trend')
            .addClass(current_data.plain > previous_data.plain ? 'is-up' : 'is-down')
        }
      })
    })
  })

  // exclude empty params from a query string
  $('.js-exclude-empty-params').submit(function () {
    $(this)
      .find('select option:selected, input:text, input:checkbox')
      .filter(function () {
        return (
          $(this).val() === '' &&
          $(this).closest('[data-react-class="UIkit.UIRangePicker"]').length === 0 &&
          $(this).closest('.js-currency-select').length === 0
        )
      })
      .each(function (i, e) {
        $(e).attr('disabled', true)
        $(e).css('background-color', '#fff') // to prevent gray blink
      })

    return true
  })

  $('.js-fraud-policies-toggle').click(function (e) {
    e.preventDefault()
    $('.js-fraud-policies').slideToggle()
  })
})

function period_type_filter_changed(period_type_filters) {
  if (!period_type_filters) {
    period_type_filters = $('select.period_type_filter')
  }
  if (period_type_filters.length === 0) {
    return false
  }

  period_type_filters.each(function () {
    const date_filter_class = $(this).attr('data-date-filter-name')

    if (date_filter_class && date_filter_class.length > 0) {
      const date_filter = $('input.' + date_filter_class)

      date_filter.prop('disabled', $(this).val() !== 'custom')
    }
  })

  return false
}

window.add_groups_to_column_filter_list = function (metrics) {
  if (typeof metrics === 'undefined') {
    return false
  }

  let last_group = ''

  $('.group-of-metrics label').each(function () {
    const input = $($(this)).children('input')[0]
    const group = metrics[$(input).val()]

    if (group && last_group !== group) {
      last_group = group
      $(this).before('<h4 class="pts b">' + group + '</h4>')
    }
  })
}
